import { isLocalhost } from 'Lib/is-localhost/is-localhost';
import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';
import { Popup } from 'Lib/popup/popup';
import { getW } from 'Lib/w/w';
import { eksmoBP } from 'Lib/bp/bp';

const gulpReplacer = '/eksmo/_verstka';

export class Reactions {
    constructor(els) {
        this._els = els;
    }

    _getRatings() {
        const reactions = {};
        let count = 0;

        this._els.forEach((item) => {
            const type = item.getAttribute('data-type');
            const id = item.getAttribute('data-id');

            if (type && id) {
                if (!reactions[type]) {
                    reactions[type] = [];
                }

                reactions[type].push(id);
                count++;
            }
        });

        this._url = isLocalhost()
            ? `${gulpReplacer}/ajax/reactions/reactions.php`
            : `/ajax/reactions/`;

        if (count) {
            fetch(this._url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reactions),
            })
                .then((data) => {
                    if (data.ok) {
                        return Promise.resolve(data.json());
                    }

                    return Promise.reject(new Error(`Ошибка: ${data.status}`));
                })
                .then((data) => {
                    this._els.forEach((item) => {
                        this._writeRatings(item, data);
                    });

                    this._setEvents();
                });
        }
    }

    _writeRatings(currItem, dataReactions) {
        this._type = currItem?.getAttribute('data-type');
        this._id = currItem?.getAttribute('data-id');
        this._htmlList = '';
        this._reaction = {};

        if (isNoEmptyString(this._type) && isNoEmptyString(this._id) && dataReactions?.[this._type]?.[this._id]) {
            this._reaction = dataReactions[this._type][this._id];
            this._elHead = currItem?.querySelector('.reactions__head');
            currItem?.querySelector('.reactions__body-list')?.remove();
            currItem?.querySelector('.reactions__count')?.remove();
            this._elHead?.insertAdjacentHTML('afterend', `<button class="reactions__count">${this._reaction.count}</button>`);

            // user_reaction - выбранная реакция юзера
            if (this._reaction.user_reaction) {
                const elIconCurrentUser = this._elHead?.querySelector('.reactions__icon_current-user');

                if (this._reaction.user_reaction === this._reaction.currentUserReaction) {
                    elIconCurrentUser?.remove();
                } else if (elIconCurrentUser) {
                    elIconCurrentUser.querySelector('use')?.setAttribute('xlink:href', `#${this._reaction.user_reaction}`);
                } else {
                    this._elHead?.insertAdjacentHTML('afterbegin', `
                        <div class="reactions__icon-box">
                            <svg class="reactions__icon reactions__icon_current-user"><use xlink:href="#${this._reaction.user_reaction}"></use></svg>
                        </div>
                    `);
                }
            }
            //

            // currentUserReaction - реакция с самым максимальным количеством голосов
            if (this._reaction.currentUserReaction) {
                currItem?.querySelector('.reactions__head .reactions__icon_most-votes use')?.setAttribute('xlink:href', `#${this._reaction.currentUserReaction}`);
            }
            //

            this._sum = this._reaction?.list?.reduce(
                (currentSum, currentValue) => currentSum + currentValue.count,
                0,
            );

            this._reaction?.list?.forEach((itemEl) => {
                this._htmlList += `
                    <div class="reactions__body-list-item">
                        <svg class="reactions__body-list-item-svg"><use xlink:href="#${itemEl.name}"></use></svg>
                        <div class="reactions__body-list-item-line">
                            <div class="reactions__body-list-item-line-inner" style="width: ${((itemEl.count / this._sum) * 100).toFixed(2)}%;"></div>
                        </div>
                        <div class="reactions__body-list-item-line-count">${itemEl.count}</div>
                    </div>
                `;
            });

            currItem.querySelector('.reactions__box').insertAdjacentHTML('afterBegin', `
                <div class="reactions__body-list">
                    <div class="reactions__body-list-title">Реакции читателей Эксмо</div>
                    <div class="reactions__body-list-box">${this._htmlList}</div>
                </div>
            `);
        }
    }

    _setEvents() {
        this._els.forEach((item) => {
            const params = {};
            const elIcon = item.querySelectorAll('.reactions__body-icon-box');

            params.type = item.closest('.reactions').getAttribute('data-type');
            params.id = item.getAttribute('data-id');

            elIcon.forEach((itemIcon) => {
                itemIcon.addEventListener('click', () => {
                    params.typeReaction = itemIcon.getAttribute('data-type');
                    this._sendRating(item, params);
                });
            });

            if (getW() < eksmoBP.xsMax) {
                const popup = new Popup('reactions', item);

                [item?.querySelector('.reactions__head'), item?.querySelector('.reactions__count')].forEach((itemPopup) => {
                    itemPopup?.addEventListener('click', () => {
                        popup.open();

                        elIcon.forEach((itemIcon) => {
                            itemIcon.addEventListener('click', () => {
                                popup.close();
                            });
                        });
                    });
                });
            } else {
                item.addEventListener('mouseover', () => {
                    item.classList.add('reactions_hover');
                });

                item.addEventListener('mouseout', () => {
                    item.classList.remove('reactions_hover');
                });
            }
        });
    }

    _sendRating(item, { id, type, typeReaction }) {
        this._url = isLocalhost()
            ? `${gulpReplacer}/ajax/reactions/send-reaction.php`
            : '/ajax/reactions/send/';

        fetch(this._url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id,
                type,
                value: typeReaction,
            }),
        })
            .then((data) => {
                if (data.ok) {
                    return Promise.resolve(data.json());
                }

                return Promise.reject(new Error(`Ошибка: ${data.status}`));
            })
            .then((data) => {
                this._keyFirst = Object.keys(data)[0];
                this._keySecond = Object.keys(data[this._keyFirst])[0];
                this._writeRatings(document.querySelector(`.reactions[data-type="${this._keyFirst}"][data-id="${this._keySecond}"]`), data);
            });
    }

    init() {
        this._getRatings();
    }
}